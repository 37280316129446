<template>
  <div class="table__container">
    <div class="transaction__filter--container bg-white py-2">
      <div class="d-flex align-items-center search__export w-100">
        <div class="d-flex pl-2">
          <b-img id="button--sort__asc--desc" class="filter-image"
            :class="filter.sort_type == 'asc' ? 'rotate-180' : ''"
            :src="require('@/assets/images/icons/Icon-order-list-down.svg')" alt="Icon-order-list-down" role="button"
            @click="
              filter.sort_type == 'asc'
                ? (filter.sort_type = 'desc')
                : (filter.sort_type = 'asc')
              " />
        </div>
        <div class="search__input w-100 px-2">
          <b-input-group>
            <b-form-input v-model="filter.search" placeholder="Cari nama" />
            <b-input-group-append is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-center mb-1" v-if="isLoading == true">
      <b-spinner label="Loading..." />
    </div>

    <vue-good-table v-else max-height="71vh" :columns="columns" :rows="rows.data" :fixed-header="false" :sort-options="{
      enabled: false,
    }" :pagination-options="{
        enabled: false,
      }">
      <template slot="table-column" slot-scope="props">
        <span v-if="props.column.field == 'action'" />
      </template>
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'address'">
          <div v-html="props.row.address"></div>
        </span>

        <!-- Payment Type -->
        <span v-else-if="props.column.field === 'payment_type'">
          <span>
            {{
              props.row.payment_type == "TOP"
                ? "Tempo " + props.row.terms_of_payment_day + " Hari"
                : "Tunai"
            }}
          </span>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown id="dropdown-dropleft" class="d-flex" right>
              <template #button-content class="btn-white text-center">
                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle" />
              </template>
              <b-dropdown-item id="button--edit__data" v-if="checkPermission('update supplier')"
                @click="editItem(props.row), (is_edit = true)">
                <span class="pr-5"> Edit Data </span>
              </b-dropdown-item>
              <!-- <b-dropdown-item
                @click="detailItem(props.row)"
              >
                <span class="pr-5">
                  Detail Data
                </span>
              </b-dropdown-item> -->
              <b-dropdown-item id="button--hapus__data" v-if="checkPermission('delete supplier')"
                @click="deleteData(props.row.uuid)">
                <span class="text-danger pr-5"> Hapus </span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>

    <div v-if="rows && isLoading == false"
      class="d-flex justify-content-between align-items-center flex-wrap bg-white border" style="padding: 0.8rem">
      <div class="d-flex align-items-center">
        <b-form-select v-model="filter.per_page" :options="['10', '15', '20']" class="mx-1" />
        <span class="text-nowrap">
          Tampilkan
          {{
            rows.total > 0 ? filter.per_page * (rows.current_page - 1) + 1 : 0
          }}
          ke
          {{
            rows.total > 0
              ? Math.min(filter.per_page * rows.current_page, rows.total)
              : 0
          }}
          dari {{ rows.total }} transaksi.
        </span>
      </div>
      <div>
        <b-row class="mx-0 align-items-center">
          <b-col>
            <pagination class="mb-0" :data="rows" :limit="4" align="right" @pagination-change-page="getDataSupplier" />
          </b-col>
        </b-row>
      </div>
    </div>

    <ModalSupplier :is_edit="is_edit" :get-data="getDataSupplier" />
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from "lodash";
import {
  BDropdown,
  BDropdownItem,
  VBToggle,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BSpinner,
  BFormSelect,
  BRow,
  BCol,
} from "bootstrap-vue";
import { mapActions } from "vuex";

import ModalSupplier from "@/components/Customer/Modal/ModalSupplier.vue";
import {
  checkPermission,
  successNotification,
  errorNotification,
} from "@/auth/utils";

import { VueGoodTable } from "vue-good-table";

export default {
  components: {
    VueGoodTable,
    BDropdown,
    BDropdownItem,
    ModalSupplier,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BSpinner,
    BFormSelect,
    BRow,
    BCol,
  },
  props: {
    isEdit: {
      type: Boolean,
      default: null,
    },
  },
  directives: {
    "b-toggle": VBToggle,
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    };
  },
  data() {
    return {
      isLoading: false,
      is_edit: null,
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: "Nama Pemasok",
          field: "name",
        },
        {
          label: "Nama Sales",
          field: "sales_name",
        },
        {
          label: "No. Telp Sales",
          field: "sales_phone_number",
        },
        {
          label: "Alamat",
          field: "address",
        },
        {
          label: "Jenis Pembayaran",
          field: "payment_type",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      rows: {},
      formPayload: {
        name: "",
        phone_number: "",
        phone_number_country: "",
        tax_number: "",
        address: "",
        sellers: [
          {
            uuid: "",
            name: "",
            phone_number: "",
            phone_number_country: "",
            salesSelectedCountry: {
              country_code: "ID",
              country_flag: "https://flagsapi.com/ID/shiny/64.png",
              country_name: "Indonesia",
              dial_code_preview: "+62",
              dial_code_value: "62",
            },
          },
        ],
        // sales_name: "",
        // sales_phone_number: "",
        // sales_phone_number_country: "",
        payment_type: "",
        selectedCountry: {
          country_code: "ID",
          country_flag: "https://flagsapi.com/ID/shiny/64.png",
          country_name: "Indonesia",
          dial_code_preview: "+62",
          dial_code_value: "62",
        },
        // salesSelectedCountry: {
        //   country_code: "ID",
        //   country_flag: "https://flagsapi.com/ID/shiny/64.png",
        //   country_name: "Indonesia",
        //   dial_code_preview: "+62",
        //   dial_code_value: "62",
        // },
      },
      filter: {
        search: "",
        per_page: 10,
        sort_type: "desc",
      },
    };
  },
  watch: {
    filter: {
      handler: _.debounce(function () {
        this.getDataSupplier();
      }, 300),
      deep: true,
    },
    isEdit(value) {
      this.is_edit = value;
    },
  },
  created() {
    this.getDataSupplier();
  },
  methods: {
    ...mapActions("supplier", ["getData"]),
    getDataSupplier(page = 1) {
      this.isLoading = true;
      const queryParams = this.filter;
      queryParams.page = page;
      this.getData({ params: queryParams })
        .then((result) => {
          this.rows = result.data.data;
          this.isLoading = false;
        })
        .catch((err) => {
          if (err.response.data.meta.messages) {
            errorNotification(this, "Oops!", err.response.data.meta.messages);
          }
          this.isLoading = false;
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
    clearItem() {
      this.$store.commit("supplier/setEditId", null);
      this.$store.commit("supplier/setFormPayload", {
        name: "",
        phone_number: "",
        phone_number_country: "",
        tax_number: "",
        address: "",
        sellers: [
          {
            uuid: "",
            name: "",
            phone_number: "",
            phone_number_country: "",
            salesSelectedCountry: {
              country_code: "ID",
              country_flag: "https://flagsapi.com/ID/shiny/64.png",
              country_name: "Indonesia",
              dial_code_preview: "+62",
              dial_code_value: "62",
            },
          },
        ],
        // sales_name: "",
        // sales_phone_number: "",
        // sales_phone_number_country: "",
        payment_type: "",
        terms_of_payment_day: 0,
        selectedCountry: {
          country_code: "ID",
          country_flag: "https://flagsapi.com/ID/shiny/64.png",
          country_name: "Indonesia",
          dial_code_preview: "+62",
          dial_code_value: "62",
        },
        // salesSelectedCountry: {
        //   country_code: "ID",
        //   country_flag: "https://flagsapi.com/ID/shiny/64.png",
        //   country_name: "Indonesia",
        //   dial_code_preview: "+62",
        //   dial_code_value: "62",
        // },
      });
    },
    detailItem(item) {
      this.$bvModal.show("modal-supplier");
      this.is_edit = false;
      this.$store.commit("supplier/setResult", item);
    },
    async deleteData(uuid) {
      this.$swal({
        title: "Konfirmasi",
        text: "Apakah kamu yakin menghapus Pemasok ini?",
        icon: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("supplier/deleteData", `/supplier/${uuid}`)
            .then(() => {
              this.getDataSupplier();
              this.$swal({
                icon: "success",
                title: "Sukses!",
                text: "Pemasok berhasil dihapus",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            })
            .catch((error) => {
              if (error.response.data.meta.messages.length > 0) {
                errorNotification(
                  this,
                  "Oops!",
                  error.response.data.meta.messages
                );
              }
            });
        }
      });
    },
    async editItem(item) {

      await this.clearItem();
      this.$store.commit("supplier/setEditId", item.uuid);
      const updatedSellers = item.sellers.map((seller) => ({
        ...seller,
        salesSelectedCountry: seller.phone_number_country,
      }));
      console.log(updatedSellers, 'data sellers');
      this.$store.commit("supplier/setFormPayload", {
        name: item.name,
        phone_number: item.phone_number,
        selectedCountry: item.phone_number_country,
        tax_number: item.tax_number,
        address: item.address,
        sellers: updatedSellers,
        payment_type: item.payment_type,
        terms_of_payment_day: item.terms_of_payment_day,
      });
      this.$bvModal.show("modal-supplier");
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@/assets/scss/variables/_variables.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";

table {
  &.vgt-table {
    border: none;
  }
}
</style>
